import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WidgetPropsI } from '../../types';

const ID_KEY = 'ig-blocks-id';
const ISCLASSIC_KEY = 'ig-blocks-isClassic';

export const setStorage = (flowAPI: PlatformControllerFlowAPI, props: WidgetPropsI): void => {
  try {
    const { storage } = flowAPI.controllerConfig.platformAPIs;
    const { accountId, isClassic } = props;
    storage.local.removeItem(ID_KEY);
    storage.local.removeItem(ISCLASSIC_KEY);
    storage.local.setItem(ID_KEY, accountId);
    storage.local.setItem(ISCLASSIC_KEY, isClassic.toString());
  } catch (error) {
    console.log('Error on storageUtils.setStorage', error);
  }
};

export const getStorage = (flowAPI: PlatformControllerFlowAPI) => {
  try {
    const { storage } = flowAPI.controllerConfig.platformAPIs;
    const accountId = generateValue(storage.local.getItem(ID_KEY));
    const isClassic = generateValue(storage.local.getItem(ISCLASSIC_KEY), true);
    return { isClassic, accountId };
  } catch (error) {
    console.log('Error on storageUtils.getStorage', error);
    return { isClassic: true, accountId: '' };
  }
};

// Generating correct values from storage
const generateValue = (value, isBoolean = false) => {
  if (
    !value ||
    value === 'false' ||
    value === 'undefined' ||
    value === 'null'
  ) {
    return isBoolean ? false : '';
  } else {
    return isBoolean ? true : value;
  }
};
