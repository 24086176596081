import { onPageReady } from './onPageReady';
import model from './model';
import { biOnPropsChange } from '../../bi';
import { selectorFactory } from '../../utils/Viewer';
import { shouldRenderWidget, updateLoadMore } from '../../utils/Editor';

export default model.createController(
  ({ $bind, $widget, flowAPI, controllerConfig }) => {
    $widget.onPropsChanged(async (prev, cur) => {
      const { shouldRender, changedData } = shouldRenderWidget(prev, cur);
      const loadMoreChange = updateLoadMore(cur, prev);

      if (shouldRender) {
        await onPageReady(controllerConfig.$w, flowAPI, $widget);
      }

      if (loadMoreChange.changed) {
        const { showLoadMoreHandler } = selectorFactory(controllerConfig.$w);
        showLoadMoreHandler(loadMoreChange.val as boolean);
      }

      if (Object.keys(changedData).length) {
        biOnPropsChange(flowAPI, changedData);
      }
    });

    return {
      pageReady: async ($w) => {
        console.log('Page Ready');
        await onPageReady($w, flowAPI, $widget);
      },
      exports: {},
    };
  },
);
