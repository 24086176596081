import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import cloneDeep from 'lodash/cloneDeep';
import { ElementRoles } from '../../enums';
import {
  generateTemplate,
  convertMediasToPG,
  selectorFactory,
  getStorage,
  PgItemType,
  createCarousel,
  getNextCursorAndHasNext,
  checkIfSameLastElement,
  getLightboxContext,
} from '../../utils/Viewer';
import { igApi } from '../../api';
import { bi } from '../../bi';
import { WidgetPropsI } from '../../types';

export const onExpandModeReady = async (
  $w,
  flowAPI: PlatformControllerFlowAPI,
  props,
) => {
  const { instance } = flowAPI.controllerConfig.appParams;
  const { isViewer, isPreview } = flowAPI.environment;
  const isViewMode = isViewer || isPreview;
  const { getAccount, getMedias } = igApi(instance);
  const { setExpandModeItem } = selectorFactory($w, flowAPI);

  if (!isViewMode) {
    await openExpandModeEditor();
  } else {
    await openExpandModeViewer();
  }

  async function openExpandModeViewer() {
    const lightboxData = getLightboxContext(flowAPI);
    const {
      igData,
      userData,
      props,
      userMedias,
      paginationInstance,
      closeModal,
      itemIndex,
    } = lightboxData;
    const { accountId, shouldLimitImages, showLoadMore, mediasOnInitialLoad } =
      props;
    // Is demo or template
    const isDemo = !accountId;

    const demoUserName =
      userData.instagramAccount?.instagramInfo?.instagramUsername;

    // Create a new pagination instance not to interfere with the widget pagination
    const newPaginationInstance = cloneDeep(paginationInstance);

    const igMedias =
      shouldLimitImages && !showLoadMore
        ? igData.slice(0, mediasOnInitialLoad)
        : igData;

    // Expand mode state variables
    let index = itemIndex;
    let isFetching;
    let selectedItem = igMedias[index];

    bi({ flowAPI }).instagramViewPost(selectedItem.type);

    // A11y
    $w(`#${ElementRoles.ExpandWidget}`).accessibility.tabIndex = -1;
    $w(`#${ElementRoles.ExpandCloseBtn}`).onClick(() => {
      // Prevent the expand from reopening onClose onEnter
      setTimeout(
        () => closeModal(flowAPI.controllerConfig.wixCodeApi.window),
        100,
      );
    });

    await getMoreItems();

    setExpandModeItem(index, igMedias, demoUserName, isDemo);

    $w(`#${ElementRoles.LeftArrowBtn}`).onClick(() => {
      setNewItem({ isPrev: true });
      setExpandModeItem(index, igMedias, demoUserName, isDemo);
      bi({ flowAPI }).instagramViewPost(selectedItem.type, true);
    });

    $w(`#${ElementRoles.RightArrowBtn}`).onClick(async () => {
      setNewItem({ isPrev: false });
      setExpandModeItem(index, igMedias, demoUserName, isDemo);
      await getMoreItems();
      bi({ flowAPI }).instagramViewPost(selectedItem.type, true);
    });

    async function getMoreItems() {
      if (shouldLimitImages && !showLoadMore) {
        return;
      }
      const cursor = getNextCursorAndHasNext(userMedias);
      if (!isFetching && index + 15 >= igMedias.length && cursor.hasNext) {
        isFetching = true;
        try {
          const medias = await getMedias(accountId, {
            paginationInstance: newPaginationInstance,
          });
          const convertedMedias = convertMediasToPG(medias);
          const isSameLast = checkIfSameLastElement(igMedias, convertedMedias);
          !isSameLast && medias && igMedias.push(...convertedMedias);
        } finally {
          isFetching = false;
        }
      }
    }

    function setNewItem({ isPrev }: { isPrev: boolean }): void {
      const incrementIndex = (length: number, i: number) => (i + 1) % length;

      const decrementIndex = (length: number, i: number) =>
        (i - 1 + length) % length;

      const changeIndex = isPrev ? decrementIndex : incrementIndex;
      index = changeIndex(igMedias.length, index);
      selectedItem = igMedias[index];
    }
  }

  async function openExpandModeEditor() {
    // On editor showing only the first media as carousel
    const { accountId } = getStorage(flowAPI) as WidgetPropsI;
    let lastProGalleryPost: PgItemType;
    let userName: string | undefined;
    let isDemo = false;
    if (accountId) {
      const userData = await getAccount(accountId as string);
      userName = userData.instagramAccount?.instagramInfo?.instagramUsername;
      const posts = await getMedias(accountId as string);
      const latestPost = posts.media?.length ? posts.media[0] : {};
      lastProGalleryPost = convertMediasToPG({ media: [latestPost] })[0];
    } else {
      // Templates or demo
      isDemo = true;
      const { templateMedias, templateUser } = await generateTemplate(
        flowAPI,
        props,
      );
      userName =
        templateUser.instagramAccount?.instagramInfo?.instagramUsername;
      lastProGalleryPost = templateMedias.length
        ? templateMedias[0]
        : ({} as PgItemType);
    }
    const generatedAlbum = createCarousel(lastProGalleryPost);
    setExpandModeItem(0, [generatedAlbum], userName, isDemo);
  }
};
